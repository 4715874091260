import React, { useState, useEffect } from 'react'

import { Card, Table, Button, Input, Popconfirm, Skeleton } from 'antd'

import { onSnapshot, collection, query, where, limit, startAfter } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { threshold, pending } from '../../const/bots'

import './User.css'

const perPage = 10

const List = () => {
  const { functions, db } = useAuth()

  const [value, setValue] = useState([])
  const [page, setPage] = useState(1)
  const [last, setLast] = useState()
  const [lastList, setLastList] = useState([])
  const [isNext, setIsNext] = useState(false)
  const [filter, setFilter] = useState('')

  useEffect(() => {
    const cond = (filter) ? '==' : '!='
    const q = (page === 1) ? query(collection(db, 'users'), where('email', cond, filter), limit(perPage + 1)) : query(collection(db, 'users'), where('email', cond, filter), startAfter(last), limit(perPage + 1))
    const unsub = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
      const lastVisible = snapshot.docs[snapshot.docs.length - 2]
      setLast(lastVisible)
      setIsNext(snapshot.docs.length !== (perPage + 1))
      let data = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id
        }
      })
      data = (snapshot.docs.length === (perPage + 1)) ? data.slice(0, perPage) : data
      setValue(data)
    })
    return () => unsub()
    // eslint-disable-next-line
  }, [db, page, filter])

  const onPrevious = () => {
    const lasts = lastList
    lasts.pop()
    setLast(lasts[lasts.length - 1])
    setLastList(lasts)
    setPage(page - 1)
  }

  const onNext = () => {
    setLastList([...lastList, last])
    setPage(page + 1)
  }

  const onSearch = (q) => {
    setFilter(q.toLowerCase().trim())
  }

  const handleEnableTester = async (uid, tester) => {
    try {
      const enableTester = httpsCallable(functions, 'enableTester-enableTester')
      await enableTester({ uid: uid, tester: tester })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'User',
      dataIndex: 'email'
    },
    {
      title: 'Threshold',
      dataIndex: 't',
      width: 200,
      render: text => threshold[text]
    },
    {
      title: 'Balance',
      dataIndex: 'u',
      width: 200,
      render: text => text.toFixed(2)
    },
    {
      title: 'Billed',
      dataIndex: 'bu',
      width: 200,
      render: text => <span style={{ color: (text === 0) ? '#000000e0' : '#dd1515' }}>{text.toFixed(2)}</span>
    },
    {
      title: 'Pending',
      dataIndex: 'p',
      width: 200,
      render: text => <span style={{ color: (text === 0) ? '#000000e0' : (text > pending) ? '#dd1515' : '#36cfc9' }}>{text}</span>
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      width: 200,
      render: (_, row) => (
        <div>
          {(!row.tester) ? (
            <Popconfirm
              title={'Are you sure to enable Tester Role?'}
              onConfirm={() => handleEnableTester(row.id, true)}
              okText='Yes'
              cancelText='No'
            >
              <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle'>Enable Tester</Button>
            </Popconfirm>
          ) : (
            <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' danger onClick={() => handleEnableTester(row.id, false)}>Disable Tester</Button>
          )}
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='user-page'>
      <Card
        title='User List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <div style={{ paddingBottom: 48 }}>
          <Input.Search placeholder='Search email' style={{ float: 'left', maxWidth: 400 }} onSearch={onSearch} enterButton />
        </div>
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={false}
        />
        <div style={{ float: 'right', marginTop: '24px' }}>
          <Button type='secondary' size='large' disabled={page === 1} onClick={() => onPrevious()}>Previous</Button>
          <span style={{ margin: '0 16px', padding: '10px 14px', border: '1px solid black' }}>{page}</span>
          <Button type='secondary' size='large' disabled={isNext} onClick={() => onNext()}>Next</Button>
        </div>
      </Card>
    </div>
  )
}

export default List
