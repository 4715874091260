import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Form as AntdForm, Card, Button, Input, Select } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { getTickers } from '../../utils/tickers'
import { exchanges } from '../../const/bots'

import './Dashboard.css'

const Form = () => {
  const navigate = useNavigate()
  const { functions } = useAuth()
  const [btnLoading, setBtnLoading] = useState(false)
  const [json, setJson] = useState()

  const [tickerForm] = AntdForm.useForm()

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      let result = {}
      if (formData.ex === 'binance') {
        const bs = await getTickers('binance', 'spot')
        const bf = await getTickers('binance', 'futures')
        const bd = await getTickers('binance', 'delivery')
        result = {
          spot: bs,
          futures: bf,
          delivery: bd
        }
      }
      if (formData.ex === 'okx') {
        const getTKS = httpsCallable(functions, 'getTickers-getTickers')
        const os = await getTKS({ ex: 'okx', type: 'spot' })
        const of = await getTKS({ ex: 'okx', type: 'futures' })
        const osw = await getTKS({ ex: 'okx', type: 'swap' })
        result = {
          spot: os.data,
          futures: of.data,
          swap: osw.data
        }
      }
      // const bks = await getTickers('bitkub', 'spot')
      // result['bitkub'] = {
      //   spot: bks
      // }
      const code = JSON.stringify(result, null, 2)
      setJson(code)
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  return (
    <div className='dashboard-page'>
      <Card
        title='Get Ticker JSON'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          form={tickerForm}
          name='tickerForm'
        >
          <AntdForm.Item name='ex' label='Exchange' rules={[{ required: true, message: 'Please select Exchange' }]}>
            <Select
              placeholder='Select Exchange'
            >
              {(exchanges.map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
              )))}
            </Select>
          </AntdForm.Item>
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => tickerForm.submit()} type='primary'><SaveOutlined />Generate JSON</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
      <Card
        title='Ticker JSON'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#ffc53d', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <Input.TextArea value={json} autoSize={{ minRows: 10 }} />
      </Card>
    </div>
  )
}

export default Form
