const okxTickers = {
  spot: [
    {
      tk: "BTC-AUD",
      balas: "AUD",
      trdas: "BTC",
      scnt: 1,
      pdec: 1,
      sdec: 7,
      min: 0.0001
    },
    {
      tk: "ETH-AUD",
      balas: "AUD",
      trdas: "ETH",
      scnt: 1,
      pdec: 1,
      sdec: 7,
      min: 0.0001
    },
    {
      tk: "USDT-AUD",
      balas: "AUD",
      trdas: "USDT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "USDC-AUD",
      balas: "AUD",
      trdas: "USDC",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "BTC-AED",
      balas: "AED",
      trdas: "BTC",
      scnt: 1,
      pdec: 0,
      sdec: 9,
      min: 0.000001
    },
    {
      tk: "ETH-AED",
      balas: "AED",
      trdas: "ETH",
      scnt: 1,
      pdec: 0,
      sdec: 8,
      min: 0.00001
    },
    {
      tk: "USDT-AED",
      balas: "AED",
      trdas: "USDT",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "BTC-BRL",
      balas: "BRL",
      trdas: "BTC",
      scnt: 1,
      pdec: 0,
      sdec: 8,
      min: 0.00001
    },
    {
      tk: "ETH-BRL",
      balas: "BRL",
      trdas: "ETH",
      scnt: 1,
      pdec: 1,
      sdec: 7,
      min: 0.0001
    },
    {
      tk: "PEPE-BRL",
      balas: "BRL",
      trdas: "PEPE",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 100000
    },
    {
      tk: "USDT-BRL",
      balas: "BRL",
      trdas: "USDT",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 1
    },
    {
      tk: "USDC-BRL",
      balas: "BRL",
      trdas: "USDC",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 1
    },
    {
      tk: "BTC-EUR",
      balas: "EUR",
      trdas: "BTC",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.0001
    },
    {
      tk: "ETH-EUR",
      balas: "EUR",
      trdas: "ETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "SOL-EUR",
      balas: "EUR",
      trdas: "SOL",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "TON-EUR",
      balas: "EUR",
      trdas: "TON",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "DOGE-EUR",
      balas: "EUR",
      trdas: "DOGE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "XRP-EUR",
      balas: "EUR",
      trdas: "XRP",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "1INCH-EUR",
      balas: "EUR",
      trdas: "1INCH",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "AAVE-EUR",
      balas: "EUR",
      trdas: "AAVE",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "ADA-EUR",
      balas: "EUR",
      trdas: "ADA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "ALGO-EUR",
      balas: "EUR",
      trdas: "ALGO",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "APE-EUR",
      balas: "EUR",
      trdas: "APE",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "APT-EUR",
      balas: "EUR",
      trdas: "APT",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 0.1
    },
    {
      tk: "ARB-EUR",
      balas: "EUR",
      trdas: "ARB",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ASTR-EUR",
      balas: "EUR",
      trdas: "ASTR",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "ATOM-EUR",
      balas: "EUR",
      trdas: "ATOM",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 0.1
    },
    {
      tk: "AVAX-EUR",
      balas: "EUR",
      trdas: "AVAX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AXS-EUR",
      balas: "EUR",
      trdas: "AXS",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 1
    },
    {
      tk: "BAL-EUR",
      balas: "EUR",
      trdas: "BAL",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAT-EUR",
      balas: "EUR",
      trdas: "BAT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CHZ-EUR",
      balas: "EUR",
      trdas: "CHZ",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "COMP-EUR",
      balas: "EUR",
      trdas: "COMP",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "CRO-EUR",
      balas: "EUR",
      trdas: "CRO",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "CRV-EUR",
      balas: "EUR",
      trdas: "CRV",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "DOT-EUR",
      balas: "EUR",
      trdas: "DOT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "DYDX-EUR",
      balas: "EUR",
      trdas: "DYDX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "EGLD-EUR",
      balas: "EUR",
      trdas: "EGLD",
      scnt: 1,
      pdec: 2,
      sdec: 5,
      min: 0.1
    },
    {
      tk: "EOS-EUR",
      balas: "EUR",
      trdas: "EOS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "FET-EUR",
      balas: "EUR",
      trdas: "FET",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "FLOW-EUR",
      balas: "EUR",
      trdas: "FLOW",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "FLR-EUR",
      balas: "EUR",
      trdas: "FLR",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "FTM-EUR",
      balas: "EUR",
      trdas: "FTM",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "FXS-EUR",
      balas: "EUR",
      trdas: "FXS",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "GRT-EUR",
      balas: "EUR",
      trdas: "GRT",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "HBAR-EUR",
      balas: "EUR",
      trdas: "HBAR",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "ICP-EUR",
      balas: "EUR",
      trdas: "ICP",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 0.1
    },
    {
      tk: "IMX-EUR",
      balas: "EUR",
      trdas: "IMX",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "INJ-EUR",
      balas: "EUR",
      trdas: "INJ",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "JTO-EUR",
      balas: "EUR",
      trdas: "JTO",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "LDO-EUR",
      balas: "EUR",
      trdas: "LDO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "LINK-EUR",
      balas: "EUR",
      trdas: "LINK",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "LTC-EUR",
      balas: "EUR",
      trdas: "LTC",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "LUNC-EUR",
      balas: "EUR",
      trdas: "LUNC",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "MANA-EUR",
      balas: "EUR",
      trdas: "MANA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "MINA-EUR",
      balas: "EUR",
      trdas: "MINA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "MKR-EUR",
      balas: "EUR",
      trdas: "MKR",
      scnt: 1,
      pdec: 1,
      sdec: 7,
      min: 0.001
    },
    {
      tk: "OP-EUR",
      balas: "EUR",
      trdas: "OP",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SAND-EUR",
      balas: "EUR",
      trdas: "SAND",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "SHIB-EUR",
      balas: "EUR",
      trdas: "SHIB",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 50000
    },
    {
      tk: "SNX-EUR",
      balas: "EUR",
      trdas: "SNX",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "STX-EUR",
      balas: "EUR",
      trdas: "STX",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SUI-EUR",
      balas: "EUR",
      trdas: "SUI",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SUSHI-EUR",
      balas: "EUR",
      trdas: "SUSHI",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "TRX-EUR",
      balas: "EUR",
      trdas: "TRX",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "UNI-EUR",
      balas: "EUR",
      trdas: "UNI",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "USDT-EUR",
      balas: "EUR",
      trdas: "USDT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "USDC-EUR",
      balas: "EUR",
      trdas: "USDC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "WIF-EUR",
      balas: "EUR",
      trdas: "WIF",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "WOO-EUR",
      balas: "EUR",
      trdas: "WOO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "XLM-EUR",
      balas: "EUR",
      trdas: "XLM",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "XTZ-EUR",
      balas: "EUR",
      trdas: "XTZ",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "YGG-EUR",
      balas: "EUR",
      trdas: "YGG",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BTC-TRY",
      balas: "TRY",
      trdas: "BTC",
      scnt: 1,
      pdec: 0,
      sdec: 9,
      min: 0.00001
    },
    {
      tk: "ETH-TRY",
      balas: "TRY",
      trdas: "ETH",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.0001
    },
    {
      tk: "USDT-TRY",
      balas: "TRY",
      trdas: "USDT",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 1
    },
    {
      tk: "BTC-USDT",
      balas: "USDT",
      trdas: "BTC",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.00001
    },
    {
      tk: "ETH-USDT",
      balas: "USDT",
      trdas: "ETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "OKB-USDT",
      balas: "USDT",
      trdas: "OKB",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "SOL-USDT",
      balas: "USDT",
      trdas: "SOL",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "TON-USDT",
      balas: "USDT",
      trdas: "TON",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "DOGE-USDT",
      balas: "USDT",
      trdas: "DOGE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "XRP-USDT",
      balas: "USDT",
      trdas: "XRP",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "OL-USDT",
      balas: "USDT",
      trdas: "OL",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "PNUT-USDT",
      balas: "USDT",
      trdas: "PNUT",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "PEPE-USDT",
      balas: "USDT",
      trdas: "PEPE",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 1000000
    },
    {
      tk: "MOODENG-USDT",
      balas: "USDT",
      trdas: "MOODENG",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "ACT-USDT",
      balas: "USDT",
      trdas: "ACT",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "1INCH-USDT",
      balas: "USDT",
      trdas: "1INCH",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "AAVE-USDT",
      balas: "USDT",
      trdas: "AAVE",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "ACA-USDT",
      balas: "USDT",
      trdas: "ACA",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "ACE-USDT",
      balas: "USDT",
      trdas: "ACE",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "ACH-USDT",
      balas: "USDT",
      trdas: "ACH",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "ADA-USDT",
      balas: "USDT",
      trdas: "ADA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "AERGO-USDT",
      balas: "USDT",
      trdas: "AERGO",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "AEVO-USDT",
      balas: "USDT",
      trdas: "AEVO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "AGLD-USDT",
      balas: "USDT",
      trdas: "AGLD",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "AIDOGE-USDT",
      balas: "USDT",
      trdas: "AIDOGE",
      scnt: 1,
      pdec: 13,
      sdec: 0,
      min: 10000000000
    },
    {
      tk: "ALCX-USDT",
      balas: "USDT",
      trdas: "ALCX",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "ALGO-USDT",
      balas: "USDT",
      trdas: "ALGO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ALPHA-USDT",
      balas: "USDT",
      trdas: "ALPHA",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "APE-USDT",
      balas: "USDT",
      trdas: "APE",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "API3-USDT",
      balas: "USDT",
      trdas: "API3",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "APT-USDT",
      balas: "USDT",
      trdas: "APT",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "AR-USDT",
      balas: "USDT",
      trdas: "AR",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "ARB-USDT",
      balas: "USDT",
      trdas: "ARB",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ARG-USDT",
      balas: "USDT",
      trdas: "ARG",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ARKM-USDT",
      balas: "USDT",
      trdas: "ARKM",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ARTY-USDT",
      balas: "USDT",
      trdas: "ARTY",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "ASTR-USDT",
      balas: "USDT",
      trdas: "ASTR",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "ATH-USDT",
      balas: "USDT",
      trdas: "ATH",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "ATOM-USDT",
      balas: "USDT",
      trdas: "ATOM",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AUCTION-USDT",
      balas: "USDT",
      trdas: "AUCTION",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AVAX-USDT",
      balas: "USDT",
      trdas: "AVAX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AXS-USDT",
      balas: "USDT",
      trdas: "AXS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "BABYDOGE-USDT",
      balas: "USDT",
      trdas: "BABYDOGE",
      scnt: 1,
      pdec: 12,
      sdec: 0,
      min: 1000000000
    },
    {
      tk: "BADGER-USDT",
      balas: "USDT",
      trdas: "BADGER",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAL-USDT",
      balas: "USDT",
      trdas: "BAL",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAND-USDT",
      balas: "USDT",
      trdas: "BAND",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BANANA-USDT",
      balas: "USDT",
      trdas: "BANANA",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "BAT-USDT",
      balas: "USDT",
      trdas: "BAT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BCH-USDT",
      balas: "USDT",
      trdas: "BCH",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "BETH-USDT",
      balas: "USDT",
      trdas: "BETH",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "BICO-USDT",
      balas: "USDT",
      trdas: "BICO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BIGTIME-USDT",
      balas: "USDT",
      trdas: "BIGTIME",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "BLUR-USDT",
      balas: "USDT",
      trdas: "BLUR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "BNB-USDT",
      balas: "USDT",
      trdas: "BNB",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "BNT-USDT",
      balas: "USDT",
      trdas: "BNT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BOME-USDT",
      balas: "USDT",
      trdas: "BOME",
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1000
    },
    {
      tk: "BONE-USDT",
      balas: "USDT",
      trdas: "BONE",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "BONK-USDT",
      balas: "USDT",
      trdas: "BONK",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "BORA-USDT",
      balas: "USDT",
      trdas: "BORA",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "BSV-USDT",
      balas: "USDT",
      trdas: "BSV",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "BTT-USDT",
      balas: "USDT",
      trdas: "BTT",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 1000000
    },
    {
      tk: "BZZ-USDT",
      balas: "USDT",
      trdas: "BZZ",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CATI-USDT",
      balas: "USDT",
      trdas: "CATI",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "CAT-USDT",
      balas: "USDT",
      trdas: "CAT",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "CELO-USDT",
      balas: "USDT",
      trdas: "CELO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CELR-USDT",
      balas: "USDT",
      trdas: "CELR",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "CETUS-USDT",
      balas: "USDT",
      trdas: "CETUS",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "CFG-USDT",
      balas: "USDT",
      trdas: "CFG",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CFX-USDT",
      balas: "USDT",
      trdas: "CFX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CHZ-USDT",
      balas: "USDT",
      trdas: "CHZ",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "CITY-USDT",
      balas: "USDT",
      trdas: "CITY",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "CLV-USDT",
      balas: "USDT",
      trdas: "CLV",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "COMP-USDT",
      balas: "USDT",
      trdas: "COMP",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "CORE-USDT",
      balas: "USDT",
      trdas: "CORE",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 1
    },
    {
      tk: "CRO-USDT",
      balas: "USDT",
      trdas: "CRO",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "CRV-USDT",
      balas: "USDT",
      trdas: "CRV",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CSPR-USDT",
      balas: "USDT",
      trdas: "CSPR",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "CTC-USDT",
      balas: "USDT",
      trdas: "CTC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CTXC-USDT",
      balas: "USDT",
      trdas: "CTXC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CVC-USDT",
      balas: "USDT",
      trdas: "CVC",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "CVX-USDT",
      balas: "USDT",
      trdas: "CVX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "CXT-USDT",
      balas: "USDT",
      trdas: "CXT",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "DAI-USDT",
      balas: "USDT",
      trdas: "DAI",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "DAO-USDT",
      balas: "USDT",
      trdas: "DAO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "DEGEN-USDT",
      balas: "USDT",
      trdas: "DEGEN",
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1000
    },
    {
      tk: "DEP-USDT",
      balas: "USDT",
      trdas: "DEP",
      scnt: 1,
      pdec: 6,
      sdec: 5,
      min: 100
    },
    {
      tk: "DGB-USDT",
      balas: "USDT",
      trdas: "DGB",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "DIA-USDT",
      balas: "USDT",
      trdas: "DIA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "DOGS-USDT",
      balas: "USDT",
      trdas: "DOGS",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 100
    },
    {
      tk: "DORA-USDT",
      balas: "USDT",
      trdas: "DORA",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "DOT-USDT",
      balas: "USDT",
      trdas: "DOT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "DYDX-USDT",
      balas: "USDT",
      trdas: "DYDX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "EGLD-USDT",
      balas: "USDT",
      trdas: "EGLD",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "EIGEN-USDT",
      balas: "USDT",
      trdas: "EIGEN",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ELF-USDT",
      balas: "USDT",
      trdas: "ELF",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ELON-USDT",
      balas: "USDT",
      trdas: "ELON",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 5000000
    },
    {
      tk: "ENJ-USDT",
      balas: "USDT",
      trdas: "ENJ",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "ENS-USDT",
      balas: "USDT",
      trdas: "ENS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "EOS-USDT",
      balas: "USDT",
      trdas: "EOS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "ERN-USDT",
      balas: "USDT",
      trdas: "ERN",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "ETC-USDT",
      balas: "USDT",
      trdas: "ETC",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "ETHW-USDT",
      balas: "USDT",
      trdas: "ETHW",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "ETHFI-USDT",
      balas: "USDT",
      trdas: "ETHFI",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "FET-USDT",
      balas: "USDT",
      trdas: "FET",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "FIL-USDT",
      balas: "USDT",
      trdas: "FIL",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "FLM-USDT",
      balas: "USDT",
      trdas: "FLM",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "FLOW-USDT",
      balas: "USDT",
      trdas: "FLOW",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "FLOKI-USDT",
      balas: "USDT",
      trdas: "FLOKI",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "FLR-USDT",
      balas: "USDT",
      trdas: "FLR",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "FORTH-USDT",
      balas: "USDT",
      trdas: "FORTH",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "FOXY-USDT",
      balas: "USDT",
      trdas: "FOXY",
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 100
    },
    {
      tk: "FTM-USDT",
      balas: "USDT",
      trdas: "FTM",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "FXS-USDT",
      balas: "USDT",
      trdas: "FXS",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "G-USDT",
      balas: "USDT",
      trdas: "G",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "GALA-USDT",
      balas: "USDT",
      trdas: "GALA",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "GALFT-USDT",
      balas: "USDT",
      trdas: "GALFT",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "GAS-USDT",
      balas: "USDT",
      trdas: "GAS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "GEAR-USDT",
      balas: "USDT",
      trdas: "GEAR",
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 100
    },
    {
      tk: "GFT-USDT",
      balas: "USDT",
      trdas: "GFT",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "GHST-USDT",
      balas: "USDT",
      trdas: "GHST",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "GLM-USDT",
      balas: "USDT",
      trdas: "GLM",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GLMR-USDT",
      balas: "USDT",
      trdas: "GLMR",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GMT-USDT",
      balas: "USDT",
      trdas: "GMT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "GMX-USDT",
      balas: "USDT",
      trdas: "GMX",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "GOAL-USDT",
      balas: "USDT",
      trdas: "GOAL",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 100
    },
    {
      tk: "GODS-USDT",
      balas: "USDT",
      trdas: "GODS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GOG-USDT",
      balas: "USDT",
      trdas: "GOG",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "GPT-USDT",
      balas: "USDT",
      trdas: "GPT",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "GRT-USDT",
      balas: "USDT",
      trdas: "GRT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "HBAR-USDT",
      balas: "USDT",
      trdas: "HBAR",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "HMSTR-USDT",
      balas: "USDT",
      trdas: "HMSTR",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "ICE-USDT",
      balas: "USDT",
      trdas: "ICE",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "ICP-USDT",
      balas: "USDT",
      trdas: "ICP",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "ICX-USDT",
      balas: "USDT",
      trdas: "ICX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ID-USDT",
      balas: "USDT",
      trdas: "ID",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "ILV-USDT",
      balas: "USDT",
      trdas: "ILV",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "IMX-USDT",
      balas: "USDT",
      trdas: "IMX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "INJ-USDT",
      balas: "USDT",
      trdas: "INJ",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "IOST-USDT",
      balas: "USDT",
      trdas: "IOST",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "IOTA-USDT",
      balas: "USDT",
      trdas: "IOTA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "IQ-USDT",
      balas: "USDT",
      trdas: "IQ",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "JOE-USDT",
      balas: "USDT",
      trdas: "JOE",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "JST-USDT",
      balas: "USDT",
      trdas: "JST",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "JTO-USDT",
      balas: "USDT",
      trdas: "JTO",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "JUP-USDT",
      balas: "USDT",
      trdas: "JUP",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "KAIA-USDT",
      balas: "USDT",
      trdas: "KAIA",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "KDA-USDT",
      balas: "USDT",
      trdas: "KDA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "KISHU-USDT",
      balas: "USDT",
      trdas: "KISHU",
      scnt: 1,
      pdec: 12,
      sdec: 0,
      min: 10000000000
    },
    {
      tk: "KNC-USDT",
      balas: "USDT",
      trdas: "KNC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "KP3R-USDT",
      balas: "USDT",
      trdas: "KP3R",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "KSM-USDT",
      balas: "USDT",
      trdas: "KSM",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "LAMB-USDT",
      balas: "USDT",
      trdas: "LAMB",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "LAT-USDT",
      balas: "USDT",
      trdas: "LAT",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "LBR-USDT",
      balas: "USDT",
      trdas: "LBR",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "LDO-USDT",
      balas: "USDT",
      trdas: "LDO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "LEO-USDT",
      balas: "USDT",
      trdas: "LEO",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "LINK-USDT",
      balas: "USDT",
      trdas: "LINK",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "LON-USDT",
      balas: "USDT",
      trdas: "LON",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "LOOKS-USDT",
      balas: "USDT",
      trdas: "LOOKS",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "LPT-USDT",
      balas: "USDT",
      trdas: "LPT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "LQTY-USDT",
      balas: "USDT",
      trdas: "LQTY",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "LRC-USDT",
      balas: "USDT",
      trdas: "LRC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "LSK-USDT",
      balas: "USDT",
      trdas: "LSK",
      scnt: 1,
      pdec: 4,
      sdec: 8,
      min: 1
    },
    {
      tk: "LTC-USDT",
      balas: "USDT",
      trdas: "LTC",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "LUNC-USDT",
      balas: "USDT",
      trdas: "LUNC",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "LUNA-USDT",
      balas: "USDT",
      trdas: "LUNA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "MAGIC-USDT",
      balas: "USDT",
      trdas: "MAGIC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "MANA-USDT",
      balas: "USDT",
      trdas: "MANA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "MASK-USDT",
      balas: "USDT",
      trdas: "MASK",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "MAX-USDT",
      balas: "USDT",
      trdas: "MAX",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "MDT-USDT",
      balas: "USDT",
      trdas: "MDT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "MEME-USDT",
      balas: "USDT",
      trdas: "MEME",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "MENGO-USDT",
      balas: "USDT",
      trdas: "MENGO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "MERL-USDT",
      balas: "USDT",
      trdas: "MERL",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "METIS-USDT",
      balas: "USDT",
      trdas: "METIS",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "MEW-USDT",
      balas: "USDT",
      trdas: "MEW",
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1000
    },
    {
      tk: "MILO-USDT",
      balas: "USDT",
      trdas: "MILO",
      scnt: 1,
      pdec: 11,
      sdec: 0,
      min: 100000000
    },
    {
      tk: "MINA-USDT",
      balas: "USDT",
      trdas: "MINA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "MKR-USDT",
      balas: "USDT",
      trdas: "MKR",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.001
    },
    {
      tk: "MLN-USDT",
      balas: "USDT",
      trdas: "MLN",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "MOVR-USDT",
      balas: "USDT",
      trdas: "MOVR",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "MXC-USDT",
      balas: "USDT",
      trdas: "MXC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "NEAR-USDT",
      balas: "USDT",
      trdas: "NEAR",
      scnt: 1,
      pdec: 3,
      sdec: 8,
      min: 1
    },
    {
      tk: "NEIRO-USDT",
      balas: "USDT",
      trdas: "NEIRO",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "NEO-USDT",
      balas: "USDT",
      trdas: "NEO",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "NFT-USDT",
      balas: "USDT",
      trdas: "NFT",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 10000000
    },
    {
      tk: "NMR-USDT",
      balas: "USDT",
      trdas: "NMR",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "NOT-USDT",
      balas: "USDT",
      trdas: "NOT",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "NULS-USDT",
      balas: "USDT",
      trdas: "NULS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "OAS-USDT",
      balas: "USDT",
      trdas: "OAS",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "OKT-USDT",
      balas: "USDT",
      trdas: "OKT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "OM-USDT",
      balas: "USDT",
      trdas: "OM",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "OMI-USDT",
      balas: "USDT",
      trdas: "OMI",
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 1000
    },
    {
      tk: "ONDO-USDT",
      balas: "USDT",
      trdas: "ONDO",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "ONE-USDT",
      balas: "USDT",
      trdas: "ONE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "ONT-USDT",
      balas: "USDT",
      trdas: "ONT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "OP-USDT",
      balas: "USDT",
      trdas: "OP",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ORBS-USDT",
      balas: "USDT",
      trdas: "ORBS",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "ORDI-USDT",
      balas: "USDT",
      trdas: "ORDI",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "OXT-USDT",
      balas: "USDT",
      trdas: "OXT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "PENDLE-USDT",
      balas: "USDT",
      trdas: "PENDLE",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "PEOPLE-USDT",
      balas: "USDT",
      trdas: "PEOPLE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "PERP-USDT",
      balas: "USDT",
      trdas: "PERP",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "PHA-USDT",
      balas: "USDT",
      trdas: "PHA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "PIXEL-USDT",
      balas: "USDT",
      trdas: "PIXEL",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "POLYDOGE-USDT",
      balas: "USDT",
      trdas: "POLYDOGE",
      scnt: 1,
      pdec: 11,
      sdec: 0,
      min: 100000000
    },
    {
      tk: "POL-USDT",
      balas: "USDT",
      trdas: "POL",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "POR-USDT",
      balas: "USDT",
      trdas: "POR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "PRCL-USDT",
      balas: "USDT",
      trdas: "PRCL",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "PRQ-USDT",
      balas: "USDT",
      trdas: "PRQ",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "PSTAKE-USDT",
      balas: "USDT",
      trdas: "PSTAKE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "PYTH-USDT",
      balas: "USDT",
      trdas: "PYTH",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "PYUSD-USDT",
      balas: "USDT",
      trdas: "PYUSD",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "QTUM-USDT",
      balas: "USDT",
      trdas: "QTUM",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "RACA-USDT",
      balas: "USDT",
      trdas: "RACA",
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 1000
    },
    {
      tk: "RADAR-USDT",
      balas: "USDT",
      trdas: "RADAR",
      scnt: 1,
      pdec: 6,
      sdec: 3,
      min: 1000
    },
    {
      tk: "RAY-USDT",
      balas: "USDT",
      trdas: "RAY",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "RDNT-USDT",
      balas: "USDT",
      trdas: "RDNT",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "RENDER-USDT",
      balas: "USDT",
      trdas: "RENDER",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "RIO-USDT",
      balas: "USDT",
      trdas: "RIO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "RON-USDT",
      balas: "USDT",
      trdas: "RON",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "RPL-USDT",
      balas: "USDT",
      trdas: "RPL",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "RSR-USDT",
      balas: "USDT",
      trdas: "RSR",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "RSS3-USDT",
      balas: "USDT",
      trdas: "RSS3",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "RUNECOIN-USDT",
      balas: "USDT",
      trdas: "RUNECOIN",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "RVN-USDT",
      balas: "USDT",
      trdas: "RVN",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SAFE-USDT",
      balas: "USDT",
      trdas: "SAFE",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "SAMO-USDT",
      balas: "USDT",
      trdas: "SAMO",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SAND-USDT",
      balas: "USDT",
      trdas: "SAND",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "SATS-USDT",
      balas: "USDT",
      trdas: "SATS",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 10000000
    },
    {
      tk: "SC-USDT",
      balas: "USDT",
      trdas: "SC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SCR-USDT",
      balas: "USDT",
      trdas: "SCR",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "SD-USDT",
      balas: "USDT",
      trdas: "SD",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "SHIB-USDT",
      balas: "USDT",
      trdas: "SHIB",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "SKL-USDT",
      balas: "USDT",
      trdas: "SKL",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SLERF-USDT",
      balas: "USDT",
      trdas: "SLERF",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "SLP-USDT",
      balas: "USDT",
      trdas: "SLP",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SNT-USDT",
      balas: "USDT",
      trdas: "SNT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SNX-USDT",
      balas: "USDT",
      trdas: "SNX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "SPURS-USDT",
      balas: "USDT",
      trdas: "SPURS",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "SSV-USDT",
      balas: "USDT",
      trdas: "SSV",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "SSWP-USDT",
      balas: "USDT",
      trdas: "SSWP",
      scnt: 1,
      pdec: 7,
      sdec: 4,
      min: 100
    },
    {
      tk: "STETH-USDT",
      balas: "USDT",
      trdas: "STETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "STORJ-USDT",
      balas: "USDT",
      trdas: "STORJ",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "STRK-USDT",
      balas: "USDT",
      trdas: "STRK",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "STX-USDT",
      balas: "USDT",
      trdas: "STX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "SUI-USDT",
      balas: "USDT",
      trdas: "SUI",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SUSHI-USDT",
      balas: "USDT",
      trdas: "SUSHI",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "SWEAT-USDT",
      balas: "USDT",
      trdas: "SWEAT",
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 100
    },
    {
      tk: "SWFTC-USDT",
      balas: "USDT",
      trdas: "SWFTC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "T-USDT",
      balas: "USDT",
      trdas: "T",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "THETA-USDT",
      balas: "USDT",
      trdas: "THETA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "TIA-USDT",
      balas: "USDT",
      trdas: "TIA",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "TNSR-USDT",
      balas: "USDT",
      trdas: "TNSR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "TRA-USDT",
      balas: "USDT",
      trdas: "TRA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "TRB-USDT",
      balas: "USDT",
      trdas: "TRB",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "TRX-USDT",
      balas: "USDT",
      trdas: "TRX",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 1
    },
    {
      tk: "TURBO-USDT",
      balas: "USDT",
      trdas: "TURBO",
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 1000
    },
    {
      tk: "ULTI-USDT",
      balas: "USDT",
      trdas: "ULTI",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "UMA-USDT",
      balas: "USDT",
      trdas: "UMA",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "UNI-USDT",
      balas: "USDT",
      trdas: "UNI",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "USDC-USDT",
      balas: "USDT",
      trdas: "USDC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "USTC-USDT",
      balas: "USDT",
      trdas: "USTC",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "UXLINK-USDT",
      balas: "USDT",
      trdas: "UXLINK",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "VELO-USDT",
      balas: "USDT",
      trdas: "VELO",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "VELODROME-USDT",
      balas: "USDT",
      trdas: "VELODROME",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "VENOM-USDT",
      balas: "USDT",
      trdas: "VENOM",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "VRA-USDT",
      balas: "USDT",
      trdas: "VRA",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "W-USDT",
      balas: "USDT",
      trdas: "W",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "WAXP-USDT",
      balas: "USDT",
      trdas: "WAXP",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "WBTC-USDT",
      balas: "USDT",
      trdas: "WBTC",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "WIF-USDT",
      balas: "USDT",
      trdas: "WIF",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "WIN-USDT",
      balas: "USDT",
      trdas: "WIN",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 100000
    },
    {
      tk: "WLD-USDT",
      balas: "USDT",
      trdas: "WLD",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "WOO-USDT",
      balas: "USDT",
      trdas: "WOO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "X-USDT",
      balas: "USDT",
      trdas: "X",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "XAUT-USDT",
      balas: "USDT",
      trdas: "XAUT",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "XCH-USDT",
      balas: "USDT",
      trdas: "XCH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "XLM-USDT",
      balas: "USDT",
      trdas: "XLM",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "XNO-USDT",
      balas: "USDT",
      trdas: "XNO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "XR-USDT",
      balas: "USDT",
      trdas: "XR",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "XTZ-USDT",
      balas: "USDT",
      trdas: "XTZ",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "YFI-USDT",
      balas: "USDT",
      trdas: "YFI",
      scnt: 1,
      pdec: 0,
      sdec: 6,
      min: 0.0005
    },
    {
      tk: "YGG-USDT",
      balas: "USDT",
      trdas: "YGG",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ZBCN-USDT",
      balas: "USDT",
      trdas: "ZBCN",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "ZENT-USDT",
      balas: "USDT",
      trdas: "ZENT",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "ZERO-USDT",
      balas: "USDT",
      trdas: "ZERO",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "ZETA-USDT",
      balas: "USDT",
      trdas: "ZETA",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "ZEUS-USDT",
      balas: "USDT",
      trdas: "ZEUS",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "ZIL-USDT",
      balas: "USDT",
      trdas: "ZIL",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "ZK-USDT",
      balas: "USDT",
      trdas: "ZK",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "ZKJ-USDT",
      balas: "USDT",
      trdas: "ZKJ",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ZRO-USDT",
      balas: "USDT",
      trdas: "ZRO",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ZRX-USDT",
      balas: "USDT",
      trdas: "ZRX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BTC-USDC",
      balas: "USDC",
      trdas: "BTC",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.00001
    },
    {
      tk: "ETH-USDC",
      balas: "USDC",
      trdas: "ETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "OKB-USDC",
      balas: "USDC",
      trdas: "OKB",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "SOL-USDC",
      balas: "USDC",
      trdas: "SOL",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "TON-USDC",
      balas: "USDC",
      trdas: "TON",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "DOGE-USDC",
      balas: "USDC",
      trdas: "DOGE",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "XRP-USDC",
      balas: "USDC",
      trdas: "XRP",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "PNUT-USDC",
      balas: "USDC",
      trdas: "PNUT",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "PEPE-USDC",
      balas: "USDC",
      trdas: "PEPE",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 1000000
    },
    {
      tk: "MOODENG-USDC",
      balas: "USDC",
      trdas: "MOODENG",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "1INCH-USDC",
      balas: "USDC",
      trdas: "1INCH",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "AAVE-USDC",
      balas: "USDC",
      trdas: "AAVE",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.01
    },
    {
      tk: "ACA-USDC",
      balas: "USDC",
      trdas: "ACA",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "ACE-USDC",
      balas: "USDC",
      trdas: "ACE",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "ADA-USDC",
      balas: "USDC",
      trdas: "ADA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "AERGO-USDC",
      balas: "USDC",
      trdas: "AERGO",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "AEVO-USDC",
      balas: "USDC",
      trdas: "AEVO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "AGLD-USDC",
      balas: "USDC",
      trdas: "AGLD",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ALCX-USDC",
      balas: "USDC",
      trdas: "ALCX",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "ALGO-USDC",
      balas: "USDC",
      trdas: "ALGO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "ALPHA-USDC",
      balas: "USDC",
      trdas: "ALPHA",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "APE-USDC",
      balas: "USDC",
      trdas: "APE",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "API3-USDC",
      balas: "USDC",
      trdas: "API3",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "APT-USDC",
      balas: "USDC",
      trdas: "APT",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "AR-USDC",
      balas: "USDC",
      trdas: "AR",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "ARB-USDC",
      balas: "USDC",
      trdas: "ARB",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ARG-USDC",
      balas: "USDC",
      trdas: "ARG",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ARKM-USDC",
      balas: "USDC",
      trdas: "ARKM",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ASTR-USDC",
      balas: "USDC",
      trdas: "ASTR",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "ATH-USDC",
      balas: "USDC",
      trdas: "ATH",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "ATOM-USDC",
      balas: "USDC",
      trdas: "ATOM",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "AUCTION-USDC",
      balas: "USDC",
      trdas: "AUCTION",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AVAX-USDC",
      balas: "USDC",
      trdas: "AVAX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "AXS-USDC",
      balas: "USDC",
      trdas: "AXS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "BABYDOGE-USDC",
      balas: "USDC",
      trdas: "BABYDOGE",
      scnt: 1,
      pdec: 12,
      sdec: 0,
      min: 1000000000
    },
    {
      tk: "BADGER-USDC",
      balas: "USDC",
      trdas: "BADGER",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAL-USDC",
      balas: "USDC",
      trdas: "BAL",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAND-USDC",
      balas: "USDC",
      trdas: "BAND",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "BAT-USDC",
      balas: "USDC",
      trdas: "BAT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BCH-USDC",
      balas: "USDC",
      trdas: "BCH",
      scnt: 1,
      pdec: 1,
      sdec: 4,
      min: 0.01
    },
    {
      tk: "BICO-USDC",
      balas: "USDC",
      trdas: "BICO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BLUR-USDC",
      balas: "USDC",
      trdas: "BLUR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "BNB-USDC",
      balas: "USDC",
      trdas: "BNB",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "BNT-USDC",
      balas: "USDC",
      trdas: "BNT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BONE-USDC",
      balas: "USDC",
      trdas: "BONE",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "BONK-USDC",
      balas: "USDC",
      trdas: "BONK",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "BORA-USDC",
      balas: "USDC",
      trdas: "BORA",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "BSV-USDC",
      balas: "USDC",
      trdas: "BSV",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "BZZ-USDC",
      balas: "USDC",
      trdas: "BZZ",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CATI-USDC",
      balas: "USDC",
      trdas: "CATI",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "CAT-USDC",
      balas: "USDC",
      trdas: "CAT",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "CELR-USDC",
      balas: "USDC",
      trdas: "CELR",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "CELO-USDC",
      balas: "USDC",
      trdas: "CELO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CETUS-USDC",
      balas: "USDC",
      trdas: "CETUS",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "CFG-USDC",
      balas: "USDC",
      trdas: "CFG",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CFX-USDC",
      balas: "USDC",
      trdas: "CFX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CHZ-USDC",
      balas: "USDC",
      trdas: "CHZ",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "CLV-USDC",
      balas: "USDC",
      trdas: "CLV",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "COMP-USDC",
      balas: "USDC",
      trdas: "COMP",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "CORE-USDC",
      balas: "USDC",
      trdas: "CORE",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 1
    },
    {
      tk: "CRO-USDC",
      balas: "USDC",
      trdas: "CRO",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "CRV-USDC",
      balas: "USDC",
      trdas: "CRV",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "CSPR-USDC",
      balas: "USDC",
      trdas: "CSPR",
      scnt: 1,
      pdec: 6,
      sdec: 4,
      min: 100
    },
    {
      tk: "CTC-USDC",
      balas: "USDC",
      trdas: "CTC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CTXC-USDC",
      balas: "USDC",
      trdas: "CTXC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "CVC-USDC",
      balas: "USDC",
      trdas: "CVC",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "CVX-USDC",
      balas: "USDC",
      trdas: "CVX",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "CXT-USDC",
      balas: "USDC",
      trdas: "CXT",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 10
    },
    {
      tk: "DAI-USDC",
      balas: "USDC",
      trdas: "DAI",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "DAO-USDC",
      balas: "USDC",
      trdas: "DAO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "DEP-USDC",
      balas: "USDC",
      trdas: "DEP",
      scnt: 1,
      pdec: 6,
      sdec: 5,
      min: 100
    },
    {
      tk: "DGB-USDC",
      balas: "USDC",
      trdas: "DGB",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "DIA-USDC",
      balas: "USDC",
      trdas: "DIA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "DOGS-USDC",
      balas: "USDC",
      trdas: "DOGS",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 100
    },
    {
      tk: "DOT-USDC",
      balas: "USDC",
      trdas: "DOT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "DYDX-USDC",
      balas: "USDC",
      trdas: "DYDX",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "EGLD-USDC",
      balas: "USDC",
      trdas: "EGLD",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "EIGEN-USDC",
      balas: "USDC",
      trdas: "EIGEN",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ELF-USDC",
      balas: "USDC",
      trdas: "ELF",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ELON-USDC",
      balas: "USDC",
      trdas: "ELON",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 5000000
    },
    {
      tk: "ENJ-USDC",
      balas: "USDC",
      trdas: "ENJ",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "ENS-USDC",
      balas: "USDC",
      trdas: "ENS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "EOS-USDC",
      balas: "USDC",
      trdas: "EOS",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ERN-USDC",
      balas: "USDC",
      trdas: "ERN",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "ETC-USDC",
      balas: "USDC",
      trdas: "ETC",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "ETHW-USDC",
      balas: "USDC",
      trdas: "ETHW",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "ETHFI-USDC",
      balas: "USDC",
      trdas: "ETHFI",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "EURC-USDC",
      balas: "USDC",
      trdas: "EURC",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "FIL-USDC",
      balas: "USDC",
      trdas: "FIL",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "FLM-USDC",
      balas: "USDC",
      trdas: "FLM",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "FLOKI-USDC",
      balas: "USDC",
      trdas: "FLOKI",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "FLOW-USDC",
      balas: "USDC",
      trdas: "FLOW",
      scnt: 1,
      pdec: 4,
      sdec: 5,
      min: 1
    },
    {
      tk: "FLR-USDC",
      balas: "USDC",
      trdas: "FLR",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "FORTH-USDC",
      balas: "USDC",
      trdas: "FORTH",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "FTM-USDC",
      balas: "USDC",
      trdas: "FTM",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "G-USDC",
      balas: "USDC",
      trdas: "G",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "GALA-USDC",
      balas: "USDC",
      trdas: "GALA",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "GAS-USDC",
      balas: "USDC",
      trdas: "GAS",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "GEAR-USDC",
      balas: "USDC",
      trdas: "GEAR",
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 100
    },
    {
      tk: "GFT-USDC",
      balas: "USDC",
      trdas: "GFT",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "GHST-USDC",
      balas: "USDC",
      trdas: "GHST",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "GLM-USDC",
      balas: "USDC",
      trdas: "GLM",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GLMR-USDC",
      balas: "USDC",
      trdas: "GLMR",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GMT-USDC",
      balas: "USDC",
      trdas: "GMT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "GOAL-USDC",
      balas: "USDC",
      trdas: "GOAL",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 100
    },
    {
      tk: "GODS-USDC",
      balas: "USDC",
      trdas: "GODS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "GOG-USDC",
      balas: "USDC",
      trdas: "GOG",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "GRT-USDC",
      balas: "USDC",
      trdas: "GRT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "HBAR-USDC",
      balas: "USDC",
      trdas: "HBAR",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "HMSTR-USDC",
      balas: "USDC",
      trdas: "HMSTR",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "ICE-USDC",
      balas: "USDC",
      trdas: "ICE",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "ICP-USDC",
      balas: "USDC",
      trdas: "ICP",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "ICX-USDC",
      balas: "USDC",
      trdas: "ICX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "ID-USDC",
      balas: "USDC",
      trdas: "ID",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "IMX-USDC",
      balas: "USDC",
      trdas: "IMX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "IOST-USDC",
      balas: "USDC",
      trdas: "IOST",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "IOTA-USDC",
      balas: "USDC",
      trdas: "IOTA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "IQ-USDC",
      balas: "USDC",
      trdas: "IQ",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "JOE-USDC",
      balas: "USDC",
      trdas: "JOE",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "JTO-USDC",
      balas: "USDC",
      trdas: "JTO",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "JUP-USDC",
      balas: "USDC",
      trdas: "JUP",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "KDA-USDC",
      balas: "USDC",
      trdas: "KDA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "KISHU-USDC",
      balas: "USDC",
      trdas: "KISHU",
      scnt: 1,
      pdec: 12,
      sdec: 0,
      min: 10000000000
    },
    {
      tk: "KNC-USDC",
      balas: "USDC",
      trdas: "KNC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "KP3R-USDC",
      balas: "USDC",
      trdas: "KP3R",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "KSM-USDC",
      balas: "USDC",
      trdas: "KSM",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "LAMB-USDC",
      balas: "USDC",
      trdas: "LAMB",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "LAT-USDC",
      balas: "USDC",
      trdas: "LAT",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "LDO-USDC",
      balas: "USDC",
      trdas: "LDO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "LEO-USDC",
      balas: "USDC",
      trdas: "LEO",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "LINK-USDC",
      balas: "USDC",
      trdas: "LINK",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "LON-USDC",
      balas: "USDC",
      trdas: "LON",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "LOOKS-USDC",
      balas: "USDC",
      trdas: "LOOKS",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "LPT-USDC",
      balas: "USDC",
      trdas: "LPT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "LQTY-USDC",
      balas: "USDC",
      trdas: "LQTY",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "LRC-USDC",
      balas: "USDC",
      trdas: "LRC",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "LSK-USDC",
      balas: "USDC",
      trdas: "LSK",
      scnt: 1,
      pdec: 4,
      sdec: 8,
      min: 1
    },
    {
      tk: "LTC-USDC",
      balas: "USDC",
      trdas: "LTC",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "LUNC-USDC",
      balas: "USDC",
      trdas: "LUNC",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 10000
    },
    {
      tk: "LUNA-USDC",
      balas: "USDC",
      trdas: "LUNA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "MAGIC-USDC",
      balas: "USDC",
      trdas: "MAGIC",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "MANA-USDC",
      balas: "USDC",
      trdas: "MANA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "MASK-USDC",
      balas: "USDC",
      trdas: "MASK",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "MDT-USDC",
      balas: "USDC",
      trdas: "MDT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "MERL-USDC",
      balas: "USDC",
      trdas: "MERL",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "METIS-USDC",
      balas: "USDC",
      trdas: "METIS",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "MILO-USDC",
      balas: "USDC",
      trdas: "MILO",
      scnt: 1,
      pdec: 11,
      sdec: 0,
      min: 100000000
    },
    {
      tk: "MINA-USDC",
      balas: "USDC",
      trdas: "MINA",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "MKR-USDC",
      balas: "USDC",
      trdas: "MKR",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "MLN-USDC",
      balas: "USDC",
      trdas: "MLN",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "MOVR-USDC",
      balas: "USDC",
      trdas: "MOVR",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "MXC-USDC",
      balas: "USDC",
      trdas: "MXC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "NEAR-USDC",
      balas: "USDC",
      trdas: "NEAR",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "NEIRO-USDC",
      balas: "USDC",
      trdas: "NEIRO",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "NEO-USDC",
      balas: "USDC",
      trdas: "NEO",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "NMR-USDC",
      balas: "USDC",
      trdas: "NMR",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "NOT-USDC",
      balas: "USDC",
      trdas: "NOT",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "NULS-USDC",
      balas: "USDC",
      trdas: "NULS",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "OAS-USDC",
      balas: "USDC",
      trdas: "OAS",
      scnt: 1,
      pdec: 5,
      sdec: 2,
      min: 100
    },
    {
      tk: "OKT-USDC",
      balas: "USDC",
      trdas: "OKT",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "OM-USDC",
      balas: "USDC",
      trdas: "OM",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 1
    },
    {
      tk: "OMI-USDC",
      balas: "USDC",
      trdas: "OMI",
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 1000
    },
    {
      tk: "ONDO-USDC",
      balas: "USDC",
      trdas: "ONDO",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "ONE-USDC",
      balas: "USDC",
      trdas: "ONE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "ONT-USDC",
      balas: "USDC",
      trdas: "ONT",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "OP-USDC",
      balas: "USDC",
      trdas: "OP",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "ORBS-USDC",
      balas: "USDC",
      trdas: "ORBS",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "ORDI-USDC",
      balas: "USDC",
      trdas: "ORDI",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "OXT-USDC",
      balas: "USDC",
      trdas: "OXT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "PENDLE-USDC",
      balas: "USDC",
      trdas: "PENDLE",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "PEOPLE-USDC",
      balas: "USDC",
      trdas: "PEOPLE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "PERP-USDC",
      balas: "USDC",
      trdas: "PERP",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "PHA-USDC",
      balas: "USDC",
      trdas: "PHA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "POL-USDC",
      balas: "USDC",
      trdas: "POL",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "POLYDOGE-USDC",
      balas: "USDC",
      trdas: "POLYDOGE",
      scnt: 1,
      pdec: 11,
      sdec: 0,
      min: 100000000
    },
    {
      tk: "POR-USDC",
      balas: "USDC",
      trdas: "POR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "PRQ-USDC",
      balas: "USDC",
      trdas: "PRQ",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 10
    },
    {
      tk: "PSTAKE-USDC",
      balas: "USDC",
      trdas: "PSTAKE",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "PYTH-USDC",
      balas: "USDC",
      trdas: "PYTH",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 10
    },
    {
      tk: "QTUM-USDC",
      balas: "USDC",
      trdas: "QTUM",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "RACA-USDC",
      balas: "USDC",
      trdas: "RACA",
      scnt: 1,
      pdec: 7,
      sdec: 3,
      min: 1000
    },
    {
      tk: "RDNT-USDC",
      balas: "USDC",
      trdas: "RDNT",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "RIO-USDC",
      balas: "USDC",
      trdas: "RIO",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "RON-USDC",
      balas: "USDC",
      trdas: "RON",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 1
    },
    {
      tk: "RPL-USDC",
      balas: "USDC",
      trdas: "RPL",
      scnt: 1,
      pdec: 2,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "RSS3-USDC",
      balas: "USDC",
      trdas: "RSS3",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "RVN-USDC",
      balas: "USDC",
      trdas: "RVN",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SAMO-USDC",
      balas: "USDC",
      trdas: "SAMO",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SAND-USDC",
      balas: "USDC",
      trdas: "SAND",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "SATS-USDC",
      balas: "USDC",
      trdas: "SATS",
      scnt: 1,
      pdec: 10,
      sdec: 0,
      min: 10000000
    },
    {
      tk: "SC-USDC",
      balas: "USDC",
      trdas: "SC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SD-USDC",
      balas: "USDC",
      trdas: "SD",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "SHIB-USDC",
      balas: "USDC",
      trdas: "SHIB",
      scnt: 1,
      pdec: 9,
      sdec: 0,
      min: 100000
    },
    {
      tk: "SKL-USDC",
      balas: "USDC",
      trdas: "SKL",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SLP-USDC",
      balas: "USDC",
      trdas: "SLP",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "SNT-USDC",
      balas: "USDC",
      trdas: "SNT",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "SNX-USDC",
      balas: "USDC",
      trdas: "SNX",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "STETH-USDC",
      balas: "USDC",
      trdas: "STETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "STORJ-USDC",
      balas: "USDC",
      trdas: "STORJ",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "STRK-USDC",
      balas: "USDC",
      trdas: "STRK",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "STX-USDC",
      balas: "USDC",
      trdas: "STX",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SUI-USDC",
      balas: "USDC",
      trdas: "SUI",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SUSHI-USDC",
      balas: "USDC",
      trdas: "SUSHI",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "SWEAT-USDC",
      balas: "USDC",
      trdas: "SWEAT",
      scnt: 1,
      pdec: 6,
      sdec: 2,
      min: 100
    },
    {
      tk: "SWFTC-USDC",
      balas: "USDC",
      trdas: "SWFTC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "T-USDC",
      balas: "USDC",
      trdas: "T",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "THETA-USDC",
      balas: "USDC",
      trdas: "THETA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "TIA-USDC",
      balas: "USDC",
      trdas: "TIA",
      scnt: 1,
      pdec: 3,
      sdec: 5,
      min: 0.1
    },
    {
      tk: "TNSR-USDC",
      balas: "USDC",
      trdas: "TNSR",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "TRA-USDC",
      balas: "USDC",
      trdas: "TRA",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "TRB-USDC",
      balas: "USDC",
      trdas: "TRB",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "TRX-USDC",
      balas: "USDC",
      trdas: "TRX",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "TURBO-USDC",
      balas: "USDC",
      trdas: "TURBO",
      scnt: 1,
      pdec: 7,
      sdec: 2,
      min: 1000
    },
    {
      tk: "UMA-USDC",
      balas: "USDC",
      trdas: "UMA",
      scnt: 1,
      pdec: 3,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "UNI-USDC",
      balas: "USDC",
      trdas: "UNI",
      scnt: 1,
      pdec: 3,
      sdec: 4,
      min: 1
    },
    {
      tk: "USDT-USDC",
      balas: "USDC",
      trdas: "USDT",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 1
    },
    {
      tk: "VELO-USDC",
      balas: "USDC",
      trdas: "VELO",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "VENOM-USDC",
      balas: "USDC",
      trdas: "VENOM",
      scnt: 1,
      pdec: 5,
      sdec: 3,
      min: 10
    },
    {
      tk: "VRA-USDC",
      balas: "USDC",
      trdas: "VRA",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 100
    },
    {
      tk: "W-USDC",
      balas: "USDC",
      trdas: "W",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "WAXP-USDC",
      balas: "USDC",
      trdas: "WAXP",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 100
    },
    {
      tk: "WBTC-USDC",
      balas: "USDC",
      trdas: "WBTC",
      scnt: 1,
      pdec: 1,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "WIF-USDC",
      balas: "USDC",
      trdas: "WIF",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "WLD-USDC",
      balas: "USDC",
      trdas: "WLD",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "XCH-USDC",
      balas: "USDC",
      trdas: "XCH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.1
    },
    {
      tk: "XLM-USDC",
      balas: "USDC",
      trdas: "XLM",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 10
    },
    {
      tk: "XNO-USDC",
      balas: "USDC",
      trdas: "XNO",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "XTZ-USDC",
      balas: "USDC",
      trdas: "XTZ",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 1
    },
    {
      tk: "YFI-USDC",
      balas: "USDC",
      trdas: "YFI",
      scnt: 1,
      pdec: 0,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "YGG-USDC",
      balas: "USDC",
      trdas: "YGG",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 10
    },
    {
      tk: "ZBCN-USDC",
      balas: "USDC",
      trdas: "ZBCN",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "ZENT-USDC",
      balas: "USDC",
      trdas: "ZENT",
      scnt: 1,
      pdec: 5,
      sdec: 1,
      min: 100
    },
    {
      tk: "ZERO-USDC",
      balas: "USDC",
      trdas: "ZERO",
      scnt: 1,
      pdec: 7,
      sdec: 0,
      min: 1000
    },
    {
      tk: "ZETA-USDC",
      balas: "USDC",
      trdas: "ZETA",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    },
    {
      tk: "ZIL-USDC",
      balas: "USDC",
      trdas: "ZIL",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 100
    },
    {
      tk: "ZK-USDC",
      balas: "USDC",
      trdas: "ZK",
      scnt: 1,
      pdec: 4,
      sdec: 2,
      min: 10
    },
    {
      tk: "ZRO-USDC",
      balas: "USDC",
      trdas: "ZRO",
      scnt: 1,
      pdec: 3,
      sdec: 3,
      min: 1
    },
    {
      tk: "ZRX-USDC",
      balas: "USDC",
      trdas: "ZRX",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 10
    },
    {
      tk: "BTC-DAI",
      balas: "DAI",
      trdas: "BTC",
      scnt: 1,
      pdec: 1,
      sdec: 8,
      min: 0.00001
    },
    {
      tk: "ETH-DAI",
      balas: "DAI",
      trdas: "ETH",
      scnt: 1,
      pdec: 2,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "ETH-BTC",
      balas: "BTC",
      trdas: "ETH",
      scnt: 1,
      pdec: 5,
      sdec: 6,
      min: 0.0001
    },
    {
      tk: "OKB-BTC",
      balas: "BTC",
      trdas: "OKB",
      scnt: 1,
      pdec: 7,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "SOL-BTC",
      balas: "BTC",
      trdas: "SOL",
      scnt: 1,
      pdec: 7,
      sdec: 4,
      min: 0.001
    },
    {
      tk: "BCH-BTC",
      balas: "BTC",
      trdas: "BCH",
      scnt: 1,
      pdec: 6,
      sdec: 4,
      min: 0.01
    },
    {
      tk: "BSV-BTC",
      balas: "BTC",
      trdas: "BSV",
      scnt: 1,
      pdec: 7,
      sdec: 4,
      min: 0.1
    },
    {
      tk: "LTC-BTC",
      balas: "BTC",
      trdas: "LTC",
      scnt: 1,
      pdec: 6,
      sdec: 6,
      min: 0.01
    },
    {
      tk: "STX-BTC",
      balas: "BTC",
      trdas: "STX",
      scnt: 1,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: "WBTC-BTC",
      balas: "BTC",
      trdas: "WBTC",
      scnt: 1,
      pdec: 4,
      sdec: 4,
      min: 0.0001
    },
    {
      tk: "SOL-ETH",
      balas: "ETH",
      trdas: "SOL",
      scnt: 1,
      pdec: 5,
      sdec: 4,
      min: 0.001
    },
    {
      tk: "BETH-ETH",
      balas: "ETH",
      trdas: "BETH",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "STETH-ETH",
      balas: "ETH",
      trdas: "STETH",
      scnt: 1,
      pdec: 4,
      sdec: 6,
      min: 0.001
    },
    {
      tk: "MEMEFI-USDT",
      balas: "USDT",
      trdas: "MEMEFI",
      scnt: 1,
      pdec: 6,
      sdec: 1,
      min: 100
    },
    {
      tk: "MORPHO-USDT",
      balas: "USDT",
      trdas: "MORPHO",
      scnt: 1,
      pdec: 4,
      sdec: 3,
      min: 1
    }
  ],
  futures: [
    {
      tk: "MEMEFI-USDT-250425",
      balas: "USDT",
      trdas: "MEMEFI",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "MAJOR-USDT-250516",
      balas: "USDT",
      trdas: "MAJOR",
      scnt: 100,
      pdec: 6,
      sdec: 2,
      min: 0.01
    },
    {
      tk: "BTC-USD-241122",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USD-241129",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USD-241227",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USD-250131",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USD-250328",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USD-250627",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-241122",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-241129",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-241227",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-250131",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-250328",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-250627",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "XRP-USD-241227",
      balas: "XRP",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LTC-USD-241227",
      balas: "LTC",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDT-241122",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDT-241129",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDT-241227",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDT-250131",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDT-250328",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDT-250627",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETH-USDT-241122",
      balas: "USDT",
      trdas: "ETH",
      scnt: 0.1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETH-USDT-241129",
      balas: "USDT",
      trdas: "ETH",
      scnt: 0.1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETH-USDT-241227",
      balas: "USDT",
      trdas: "ETH",
      scnt: 0.1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETH-USDT-250328",
      balas: "USDT",
      trdas: "ETH",
      scnt: 0.1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "XRP-USDT-241227",
      balas: "USDT",
      trdas: "XRP",
      scnt: 100,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "LTC-USDT-241227",
      balas: "USDT",
      trdas: "LTC",
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BTC-USDC-241122",
      balas: "USDC",
      trdas: "BTC",
      scnt: 0.0001,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDC-241129",
      balas: "USDC",
      trdas: "BTC",
      scnt: 0.0001,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDC-241227",
      balas: "USDC",
      trdas: "BTC",
      scnt: 0.0001,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDC-250328",
      balas: "USDC",
      trdas: "BTC",
      scnt: 0.0001,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USDC-241122",
      balas: "USDC",
      trdas: "ETH",
      scnt: 0.001,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USDC-241129",
      balas: "USDC",
      trdas: "ETH",
      scnt: 0.001,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USDC-241227",
      balas: "USDC",
      trdas: "ETH",
      scnt: 0.001,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USDC-250328",
      balas: "USDC",
      trdas: "ETH",
      scnt: 0.001,
      pdec: 2,
      sdec: 0,
      min: 1
    }
  ],
  swap: [
    {
      tk: "BTC-USD-SWAP",
      balas: "BTC",
      trdas: "USD",
      scnt: 100,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USD-SWAP",
      balas: "ETH",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "SOL-USD-SWAP",
      balas: "SOL",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "TON-USD-SWAP",
      balas: "TON",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOGE-USD-SWAP",
      balas: "DOGE",
      trdas: "USD",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "XRP-USD-SWAP",
      balas: "XRP",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "1INCH-USD-SWAP",
      balas: "1INCH",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ADA-USD-SWAP",
      balas: "ADA",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ALGO-USD-SWAP",
      balas: "ALGO",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ATOM-USD-SWAP",
      balas: "ATOM",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "AVAX-USD-SWAP",
      balas: "AVAX",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "BCH-USD-SWAP",
      balas: "BCH",
      trdas: "USD",
      scnt: 10,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BSV-USD-SWAP",
      balas: "BSV",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "CRV-USD-SWAP",
      balas: "CRV",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOT-USD-SWAP",
      balas: "DOT",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "EOS-USD-SWAP",
      balas: "EOS",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETC-USD-SWAP",
      balas: "ETC",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "FIL-USD-SWAP",
      balas: "FIL",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "GRT-USD-SWAP",
      balas: "GRT",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LINK-USD-SWAP",
      balas: "LINK",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "LTC-USD-SWAP",
      balas: "LTC",
      trdas: "USD",
      scnt: 10,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "MANA-USD-SWAP",
      balas: "MANA",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "NEO-USD-SWAP",
      balas: "NEO",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "OP-USD-SWAP",
      balas: "OP",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SAND-USD-SWAP",
      balas: "SAND",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SUI-USD-SWAP",
      balas: "SUI",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SUSHI-USD-SWAP",
      balas: "SUSHI",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "THETA-USD-SWAP",
      balas: "THETA",
      trdas: "USD",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "TRX-USD-SWAP",
      balas: "TRX",
      trdas: "USD",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "UNI-USD-SWAP",
      balas: "UNI",
      trdas: "USD",
      scnt: 10,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "XLM-USD-SWAP",
      balas: "XLM",
      trdas: "USD",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "YFI-USD-SWAP",
      balas: "YFI",
      trdas: "USD",
      scnt: 10,
      pdec: 0,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDT-SWAP",
      balas: "USDT",
      trdas: "BTC",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETH-USDT-SWAP",
      balas: "USDT",
      trdas: "ETH",
      scnt: 0.1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "SOL-USDT-SWAP",
      balas: "USDT",
      trdas: "SOL",
      scnt: 1,
      pdec: 2,
      sdec: 2,
      min: 0.01
    },
    {
      tk: "TON-USDT-SWAP",
      balas: "USDT",
      trdas: "TON",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOGE-USDT-SWAP",
      balas: "USDT",
      trdas: "DOGE",
      scnt: 1000,
      pdec: 5,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "XRP-USDT-SWAP",
      balas: "USDT",
      trdas: "XRP",
      scnt: 100,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "UXLINK-USDT-SWAP",
      balas: "USDT",
      trdas: "UXLINK",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "PNUT-USDT-SWAP",
      balas: "USDT",
      trdas: "PNUT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "PEPE-USDT-SWAP",
      balas: "USDT",
      trdas: "PEPE",
      scnt: 10000000,
      pdec: 9,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "MOODENG-USDT-SWAP",
      balas: "USDT",
      trdas: "MOODENG",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ACT-USDT-SWAP",
      balas: "USDT",
      trdas: "ACT",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "1INCH-USDT-SWAP",
      balas: "USDT",
      trdas: "1INCH",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "AAVE-USDT-SWAP",
      balas: "USDT",
      trdas: "AAVE",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "ACE-USDT-SWAP",
      balas: "USDT",
      trdas: "ACE",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ACH-USDT-SWAP",
      balas: "USDT",
      trdas: "ACH",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ADA-USDT-SWAP",
      balas: "USDT",
      trdas: "ADA",
      scnt: 100,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "AEVO-USDT-SWAP",
      balas: "USDT",
      trdas: "AEVO",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "AGLD-USDT-SWAP",
      balas: "USDT",
      trdas: "AGLD",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "AIDOGE-USDT-SWAP",
      balas: "USDT",
      trdas: "AIDOGE",
      scnt: 10000000000,
      pdec: 13,
      sdec: 0,
      min: 1
    },
    {
      tk: "ALGO-USDT-SWAP",
      balas: "USDT",
      trdas: "ALGO",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ALPHA-USDT-SWAP",
      balas: "USDT",
      trdas: "ALPHA",
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "APE-USDT-SWAP",
      balas: "USDT",
      trdas: "APE",
      scnt: 0.1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "API3-USDT-SWAP",
      balas: "USDT",
      trdas: "API3",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "APT-USDT-SWAP",
      balas: "USDT",
      trdas: "APT",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "AR-USDT-SWAP",
      balas: "USDT",
      trdas: "AR",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ARB-USDT-SWAP",
      balas: "USDT",
      trdas: "ARB",
      scnt: 10,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ATH-USDT-SWAP",
      balas: "USDT",
      trdas: "ATH",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ATOM-USDT-SWAP",
      balas: "USDT",
      trdas: "ATOM",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "AUCTION-USDT-SWAP",
      balas: "USDT",
      trdas: "AUCTION",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "AVAX-USDT-SWAP",
      balas: "USDT",
      trdas: "AVAX",
      scnt: 1,
      pdec: 3,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "AXS-USDT-SWAP",
      balas: "USDT",
      trdas: "AXS",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "BADGER-USDT-SWAP",
      balas: "USDT",
      trdas: "BADGER",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "BAL-USDT-SWAP",
      balas: "USDT",
      trdas: "BAL",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "BAND-USDT-SWAP",
      balas: "USDT",
      trdas: "BAND",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "BAT-USDT-SWAP",
      balas: "USDT",
      trdas: "BAT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BCH-USDT-SWAP",
      balas: "USDT",
      trdas: "BCH",
      scnt: 0.1,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "BICO-USDT-SWAP",
      balas: "USDT",
      trdas: "BICO",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BIGTIME-USDT-SWAP",
      balas: "USDT",
      trdas: "BIGTIME",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "BLUR-USDT-SWAP",
      balas: "USDT",
      trdas: "BLUR",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BNB-USDT-SWAP",
      balas: "USDT",
      trdas: "BNB",
      scnt: 0.01,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "BNT-USDT-SWAP",
      balas: "USDT",
      trdas: "BNT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BOME-USDT-SWAP",
      balas: "USDT",
      trdas: "BOME",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "BONK-USDT-SWAP",
      balas: "USDT",
      trdas: "BONK",
      scnt: 100000,
      pdec: 9,
      sdec: 0,
      min: 1
    },
    {
      tk: "BONE-USDT-SWAP",
      balas: "USDT",
      trdas: "BONE",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BRETT-USDT-SWAP",
      balas: "USDT",
      trdas: "BRETT",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "BSV-USDT-SWAP",
      balas: "USDT",
      trdas: "BSV",
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "CAT-USDT-SWAP",
      balas: "USDT",
      trdas: "CAT",
      scnt: 100000,
      pdec: 9,
      sdec: 0,
      min: 1
    },
    {
      tk: "CATI-USDT-SWAP",
      balas: "USDT",
      trdas: "CATI",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CELO-USDT-SWAP",
      balas: "USDT",
      trdas: "CELO",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CETUS-USDT-SWAP",
      balas: "USDT",
      trdas: "CETUS",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "CFX-USDT-SWAP",
      balas: "USDT",
      trdas: "CFX",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CHZ-USDT-SWAP",
      balas: "USDT",
      trdas: "CHZ",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "COMP-USDT-SWAP",
      balas: "USDT",
      trdas: "COMP",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "CORE-USDT-SWAP",
      balas: "USDT",
      trdas: "CORE",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CRO-USDT-SWAP",
      balas: "USDT",
      trdas: "CRO",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "CRV-USDT-SWAP",
      balas: "USDT",
      trdas: "CRV",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CSPR-USDT-SWAP",
      balas: "USDT",
      trdas: "CSPR",
      scnt: 1,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "CTC-USDT-SWAP",
      balas: "USDT",
      trdas: "CTC",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "CVC-USDT-SWAP",
      balas: "USDT",
      trdas: "CVC",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "CVX-USDT-SWAP",
      balas: "USDT",
      trdas: "CVX",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "DGB-USDT-SWAP",
      balas: "USDT",
      trdas: "DGB",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOGS-USDT-SWAP",
      balas: "USDT",
      trdas: "DOGS",
      scnt: 1000,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOG-USDT-SWAP",
      balas: "USDT",
      trdas: "DOG",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "DOT-USDT-SWAP",
      balas: "USDT",
      trdas: "DOT",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "DYDX-USDT-SWAP",
      balas: "USDT",
      trdas: "DYDX",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "EGLD-USDT-SWAP",
      balas: "USDT",
      trdas: "EGLD",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "EIGEN-USDT-SWAP",
      balas: "USDT",
      trdas: "EIGEN",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ENJ-USDT-SWAP",
      balas: "USDT",
      trdas: "ENJ",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ENS-USDT-SWAP",
      balas: "USDT",
      trdas: "ENS",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "EOS-USDT-SWAP",
      balas: "USDT",
      trdas: "EOS",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETC-USDT-SWAP",
      balas: "USDT",
      trdas: "ETC",
      scnt: 10,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ETHW-USDT-SWAP",
      balas: "USDT",
      trdas: "ETHW",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETHFI-USDT-SWAP",
      balas: "USDT",
      trdas: "ETHFI",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "FIL-USDT-SWAP",
      balas: "USDT",
      trdas: "FIL",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "FLM-USDT-SWAP",
      balas: "USDT",
      trdas: "FLM",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "FLOKI-USDT-SWAP",
      balas: "USDT",
      trdas: "FLOKI",
      scnt: 100000,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: "FLOW-USDT-SWAP",
      balas: "USDT",
      trdas: "FLOW",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "FOXY-USDT-SWAP",
      balas: "USDT",
      trdas: "FOXY",
      scnt: 100,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: "FTM-USDT-SWAP",
      balas: "USDT",
      trdas: "FTM",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "FXS-USDT-SWAP",
      balas: "USDT",
      trdas: "FXS",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "GALA-USDT-SWAP",
      balas: "USDT",
      trdas: "GALA",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "GAS-USDT-SWAP",
      balas: "USDT",
      trdas: "GAS",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "GFT-USDT-SWAP",
      balas: "USDT",
      trdas: "GFT",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "GLM-USDT-SWAP",
      balas: "USDT",
      trdas: "GLM",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "GMT-USDT-SWAP",
      balas: "USDT",
      trdas: "GMT",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "GMX-USDT-SWAP",
      balas: "USDT",
      trdas: "GMX",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "GOAT-USDT-SWAP",
      balas: "USDT",
      trdas: "GOAT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "GODS-USDT-SWAP",
      balas: "USDT",
      trdas: "GODS",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "GRASS-USDT-SWAP",
      balas: "USDT",
      trdas: "GRASS",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "GRT-USDT-SWAP",
      balas: "USDT",
      trdas: "GRT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "HBAR-USDT-SWAP",
      balas: "USDT",
      trdas: "HBAR",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "HMSTR-USDT-SWAP",
      balas: "USDT",
      trdas: "HMSTR",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "ICP-USDT-SWAP",
      balas: "USDT",
      trdas: "ICP",
      scnt: 0.01,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ICX-USDT-SWAP",
      balas: "USDT",
      trdas: "ICX",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ID-USDT-SWAP",
      balas: "USDT",
      trdas: "ID",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "IMX-USDT-SWAP",
      balas: "USDT",
      trdas: "IMX",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "INJ-USDT-SWAP",
      balas: "USDT",
      trdas: "INJ",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "IOST-USDT-SWAP",
      balas: "USDT",
      trdas: "IOST",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "IOTA-USDT-SWAP",
      balas: "USDT",
      trdas: "IOTA",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "JOE-USDT-SWAP",
      balas: "USDT",
      trdas: "JOE",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "JST-USDT-SWAP",
      balas: "USDT",
      trdas: "JST",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "JTO-USDT-SWAP",
      balas: "USDT",
      trdas: "JTO",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "JUP-USDT-SWAP",
      balas: "USDT",
      trdas: "JUP",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "KISHU-USDT-SWAP",
      balas: "USDT",
      trdas: "KISHU",
      scnt: 1000000000,
      pdec: 12,
      sdec: 0,
      min: 1
    },
    {
      tk: "KNC-USDT-SWAP",
      balas: "USDT",
      trdas: "KNC",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "KSM-USDT-SWAP",
      balas: "USDT",
      trdas: "KSM",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "LDO-USDT-SWAP",
      balas: "USDT",
      trdas: "LDO",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LINK-USDT-SWAP",
      balas: "USDT",
      trdas: "LINK",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "LOOKS-USDT-SWAP",
      balas: "USDT",
      trdas: "LOOKS",
      scnt: 1,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "LPT-USDT-SWAP",
      balas: "USDT",
      trdas: "LPT",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "LQTY-USDT-SWAP",
      balas: "USDT",
      trdas: "LQTY",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LRC-USDT-SWAP",
      balas: "USDT",
      trdas: "LRC",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LSK-USDT-SWAP",
      balas: "USDT",
      trdas: "LSK",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LTC-USDT-SWAP",
      balas: "USDT",
      trdas: "LTC",
      scnt: 1,
      pdec: 2,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "LUNA-USDT-SWAP",
      balas: "USDT",
      trdas: "LUNA",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "LUNC-USDT-SWAP",
      balas: "USDT",
      trdas: "LUNC",
      scnt: 10000,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: "MAGIC-USDT-SWAP",
      balas: "USDT",
      trdas: "MAGIC",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "MANA-USDT-SWAP",
      balas: "USDT",
      trdas: "MANA",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "MASK-USDT-SWAP",
      balas: "USDT",
      trdas: "MASK",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "MAX-USDT-SWAP",
      balas: "USDT",
      trdas: "MAX",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "MEME-USDT-SWAP",
      balas: "USDT",
      trdas: "MEME",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "MERL-USDT-SWAP",
      balas: "USDT",
      trdas: "MERL",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "METIS-USDT-SWAP",
      balas: "USDT",
      trdas: "METIS",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "MEW-USDT-SWAP",
      balas: "USDT",
      trdas: "MEW",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "MINA-USDT-SWAP",
      balas: "USDT",
      trdas: "MINA",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "MKR-USDT-SWAP",
      balas: "USDT",
      trdas: "MKR",
      scnt: 0.01,
      pdec: 1,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "MOVR-USDT-SWAP",
      balas: "USDT",
      trdas: "MOVR",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "NEAR-USDT-SWAP",
      balas: "USDT",
      trdas: "NEAR",
      scnt: 10,
      pdec: 3,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "NEIROETH-USDT-SWAP",
      balas: "USDT",
      trdas: "NEIROETH",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "NEIRO-USDT-SWAP",
      balas: "USDT",
      trdas: "NEIRO",
      scnt: 1000,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: "NEO-USDT-SWAP",
      balas: "USDT",
      trdas: "NEO",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "NFT-USDT-SWAP",
      balas: "USDT",
      trdas: "NFT",
      scnt: 1000000,
      pdec: 10,
      sdec: 0,
      min: 1
    },
    {
      tk: "NMR-USDT-SWAP",
      balas: "USDT",
      trdas: "NMR",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "NOT-USDT-SWAP",
      balas: "USDT",
      trdas: "NOT",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "OM-USDT-SWAP",
      balas: "USDT",
      trdas: "OM",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ONDO-USDT-SWAP",
      balas: "USDT",
      trdas: "ONDO",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ONE-USDT-SWAP",
      balas: "USDT",
      trdas: "ONE",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ONT-USDT-SWAP",
      balas: "USDT",
      trdas: "ONT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "OP-USDT-SWAP",
      balas: "USDT",
      trdas: "OP",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ORBS-USDT-SWAP",
      balas: "USDT",
      trdas: "ORBS",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ORDI-USDT-SWAP",
      balas: "USDT",
      trdas: "ORDI",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "PEOPLE-USDT-SWAP",
      balas: "USDT",
      trdas: "PEOPLE",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "PERP-USDT-SWAP",
      balas: "USDT",
      trdas: "PERP",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "POL-USDT-SWAP",
      balas: "USDT",
      trdas: "POL",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "POPCAT-USDT-SWAP",
      balas: "USDT",
      trdas: "POPCAT",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "PRCL-USDT-SWAP",
      balas: "USDT",
      trdas: "PRCL",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "PUFFER-USDT-SWAP",
      balas: "USDT",
      trdas: "PUFFER",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "PYTH-USDT-SWAP",
      balas: "USDT",
      trdas: "PYTH",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "QTUM-USDT-SWAP",
      balas: "USDT",
      trdas: "QTUM",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "RACA-USDT-SWAP",
      balas: "USDT",
      trdas: "RACA",
      scnt: 10000,
      pdec: 7,
      sdec: 0,
      min: 1
    },
    {
      tk: "RAY-USDT-SWAP",
      balas: "USDT",
      trdas: "RAY",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "RDNT-USDT-SWAP",
      balas: "USDT",
      trdas: "RDNT",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "RENDER-USDT-SWAP",
      balas: "USDT",
      trdas: "RENDER",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "RON-USDT-SWAP",
      balas: "USDT",
      trdas: "RON",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "RSR-USDT-SWAP",
      balas: "USDT",
      trdas: "RSR",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "RVN-USDT-SWAP",
      balas: "USDT",
      trdas: "RVN",
      scnt: 10,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "SAND-USDT-SWAP",
      balas: "USDT",
      trdas: "SAND",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SATS-USDT-SWAP",
      balas: "USDT",
      trdas: "SATS",
      scnt: 10000000,
      pdec: 11,
      sdec: 0,
      min: 1
    },
    {
      tk: "SCR-USDT-SWAP",
      balas: "USDT",
      trdas: "SCR",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SHIB-USDT-SWAP",
      balas: "USDT",
      trdas: "SHIB",
      scnt: 1000000,
      pdec: 9,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "SLP-USDT-SWAP",
      balas: "USDT",
      trdas: "SLP",
      scnt: 10,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "SNX-USDT-SWAP",
      balas: "USDT",
      trdas: "SNX",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "SSV-USDT-SWAP",
      balas: "USDT",
      trdas: "SSV",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "STORJ-USDT-SWAP",
      balas: "USDT",
      trdas: "STORJ",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "STRK-USDT-SWAP",
      balas: "USDT",
      trdas: "STRK",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "STX-USDT-SWAP",
      balas: "USDT",
      trdas: "STX",
      scnt: 10,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "SUI-USDT-SWAP",
      balas: "USDT",
      trdas: "SUI",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SUSHI-USDT-SWAP",
      balas: "USDT",
      trdas: "SUSHI",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "SWEAT-USDT-SWAP",
      balas: "USDT",
      trdas: "SWEAT",
      scnt: 100,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "T-USDT-SWAP",
      balas: "USDT",
      trdas: "T",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "TAO-USDT-SWAP",
      balas: "USDT",
      trdas: "TAO",
      scnt: 0.01,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "THETA-USDT-SWAP",
      balas: "USDT",
      trdas: "THETA",
      scnt: 10,
      pdec: 4,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "TIA-USDT-SWAP",
      balas: "USDT",
      trdas: "TIA",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "TNSR-USDT-SWAP",
      balas: "USDT",
      trdas: "TNSR",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "TRB-USDT-SWAP",
      balas: "USDT",
      trdas: "TRB",
      scnt: 0.1,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "TRX-USDT-SWAP",
      balas: "USDT",
      trdas: "TRX",
      scnt: 1000,
      pdec: 5,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "TURBO-USDT-SWAP",
      balas: "USDT",
      trdas: "TURBO",
      scnt: 10000,
      pdec: 7,
      sdec: 1,
      min: 0.1
    },
    {
      tk: "ULTI-USDT-SWAP",
      balas: "USDT",
      trdas: "ULTI",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "UMA-USDT-SWAP",
      balas: "USDT",
      trdas: "UMA",
      scnt: 0.1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "UNI-USDT-SWAP",
      balas: "USDT",
      trdas: "UNI",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "USDC-USDT-SWAP",
      balas: "USDT",
      trdas: "USDC",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "USTC-USDT-SWAP",
      balas: "USDT",
      trdas: "USTC",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "VELO-USDT-SWAP",
      balas: "USDT",
      trdas: "VELO",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "VRA-USDT-SWAP",
      balas: "USDT",
      trdas: "VRA",
      scnt: 1000,
      pdec: 6,
      sdec: 0,
      min: 1
    },
    {
      tk: "W-USDT-SWAP",
      balas: "USDT",
      trdas: "W",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "WAXP-USDT-SWAP",
      balas: "USDT",
      trdas: "WAXP",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "WIF-USDT-SWAP",
      balas: "USDT",
      trdas: "WIF",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "WLD-USDT-SWAP",
      balas: "USDT",
      trdas: "WLD",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "WOO-USDT-SWAP",
      balas: "USDT",
      trdas: "WOO",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "X-USDT-SWAP",
      balas: "USDT",
      trdas: "X",
      scnt: 10000,
      pdec: 8,
      sdec: 0,
      min: 1
    },
    {
      tk: "XCH-USDT-SWAP",
      balas: "USDT",
      trdas: "XCH",
      scnt: 0.01,
      pdec: 2,
      sdec: 0,
      min: 1
    },
    {
      tk: "XLM-USDT-SWAP",
      balas: "USDT",
      trdas: "XLM",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "XTZ-USDT-SWAP",
      balas: "USDT",
      trdas: "XTZ",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "YFI-USDT-SWAP",
      balas: "USDT",
      trdas: "YFI",
      scnt: 0.0001,
      pdec: 0,
      sdec: 0,
      min: 1
    },
    {
      tk: "YGG-USDT-SWAP",
      balas: "USDT",
      trdas: "YGG",
      scnt: 1,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZENT-USDT-SWAP",
      balas: "USDT",
      trdas: "ZENT",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZETA-USDT-SWAP",
      balas: "USDT",
      trdas: "ZETA",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZIL-USDT-SWAP",
      balas: "USDT",
      trdas: "ZIL",
      scnt: 100,
      pdec: 5,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZK-USDT-SWAP",
      balas: "USDT",
      trdas: "ZK",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZRO-USDT-SWAP",
      balas: "USDT",
      trdas: "ZRO",
      scnt: 1,
      pdec: 3,
      sdec: 0,
      min: 1
    },
    {
      tk: "ZRX-USDT-SWAP",
      balas: "USDT",
      trdas: "ZRX",
      scnt: 10,
      pdec: 4,
      sdec: 0,
      min: 1
    },
    {
      tk: "BTC-USDC-SWAP",
      balas: "USDC",
      trdas: "BTC",
      scnt: 0.0001,
      pdec: 1,
      sdec: 0,
      min: 1
    },
    {
      tk: "ETH-USDC-SWAP",
      balas: "USDC",
      trdas: "ETH",
      scnt: 0.001,
      pdec: 2,
      sdec: 0,
      min: 1
    }
  ]
}

export {
  okxTickers
}
