import React from 'react'
import { useAsync } from 'react-use'

import { Card, Table, Skeleton } from 'antd'

import { getAggregateFromServer, query, collection, where, count, sum } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { rate, totalBotLimit } from '../../const/bots'

import './Dashboard.css'

const KeyList = () => {
  const { db } = useAuth()

  const { value, loading } = useAsync(async () => {
    const q11 = query(collection(db, 'bots'), where('active', '==', true), where('ex', '==', 'binance'), where('ksi', '==', 1))
    const q21 = query(collection(db, 'bots'), where('active', '==', true), where('ex', '==', 'okx'), where('ksi', '==', 1))
    const snap11 = await getAggregateFromServer(q11, {
      countOfDocs: count(),
      totalPopulation: sum('fr')
    })
    const snap21 = await getAggregateFromServer(q21, {
      countOfDocs: count(),
      totalPopulation: sum('fr')
    })
    return [
      {
        id: 'Binance',
        bots: snap11.data().countOfDocs,
        usage: snap11.data().totalPopulation,
        ksi: [
          {
            id: '1',
            bots: snap11.data().countOfDocs,
            usage: snap11.data().totalPopulation
          }
        ]
      }, {
        id: 'OKX',
        bots: snap21.data().countOfDocs,
        usage: snap21.data().totalPopulation,
        ksi: [
          {
            id: '1',
            bots: snap21.data().countOfDocs,
            usage: snap21.data().totalPopulation
          }
        ]
      }
    ]
  })

  const columns = [
    {
      title: 'Exchange',
      dataIndex: 'id',
      width: 200,
    },
    {
      title: 'Total Bots',
      dataIndex: 'bots',
      render: data => new Intl.NumberFormat().format(data ?? 0)
    },
    {
      title: 'Total Usage',
      dataIndex: 'usage',
      render: data => new Intl.NumberFormat().format((data ?? 0) * rate)
    }
  ]

  const expandedColumns = [
    {
      title: 'KSI',
      dataIndex: 'id',
      width: 200,
    },
    {
      title: 'Total Bots',
      dataIndex: 'bots',
      render: data => <span style={{ color: (data > totalBotLimit) ? '#dd1515' : '#000000e0' }}>{new Intl.NumberFormat().format(data ?? 0)}</span>
    },
    {
      title: 'Total Usage',
      dataIndex: 'usage',
      render: data => new Intl.NumberFormat().format((data ?? 0) * rate)
    }
  ]

  const expandedRowRender = (data) => {
    return <Table rowKey='id' columns={expandedColumns} dataSource={data.ksi} pagination={false} />
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      <Card
        title='Stat'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <Table
          bordered
          rowKey='id'
          style={{ overflowX: 'auto' }}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record)
          }}
          dataSource={value}
          pagination={false}
        />
      </Card>
    </div>
  )
}

export default KeyList
