import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'

import AuthLayout from '../layouts/AuthLayout/AuthLayout'
import Login from '../pages/LoginPage/Login'

import AdminLayout from '../layouts/AdminLayout/AdminLayout'
import DashboardPage from '../pages/Dashboard/DashboardPage'
import Dashboard from '../pages/Dashboard/Dashboard'
import DashboardBillingList from '../pages/Dashboard/BillingList'
import DashboardBillingForm from '../pages/Dashboard/BillingForm'
import DashboardBillingApproval from '../pages/Dashboard/BillingApproval'
import DashboardTickerForm from '../pages/Dashboard/TickerForm'
import DashboardGenTickerForm from '../pages/Dashboard/GenTickerForm'
import DashboardStat from '../pages/Dashboard/Stat'
import UserPage from '../pages/UserPage/UserPage'
import UserList from '../pages/UserPage/List'
import BotPage from '../pages/BotPage/BotPage'
import TVList from '../pages/BotPage/TV/List'
import TVForm from '../pages/BotPage/TV/Form'
import BillingPage from '../pages/BillingPage/BillingPage'
import BillingList from '../pages/BillingPage/List'
import BillingForm from '../pages/BillingPage/Form'
import SettingPage from '../pages/SettingPage/SettingPage'
import Setting from '../pages/SettingPage/Setting'
import KeyList from '../pages/SettingPage/KeyList'
import KeyForm from '../pages/SettingPage/KeyForm'
import TokenList from '../pages/SettingPage/TokenList'
import TokenForm from '../pages/SettingPage/TokenForm'

import Forbidden from '../pages/403/Forbidden'
import NotFound from '../pages/404/NotFound'

const Index = () => {
  return (
    <Routes>
      <Route path='/' element={<AuthLayout />} >
        <Route index element={<Login />} />
        <Route path='login' element={<Login />} />
      </Route>
      <Route path='/admin' element={<AdminLayout />} >
        <Route
          index
          element={<Dashboard />}
        />
        <Route path='dashboard' element={<DashboardPage />} >
          <Route
            index
            element={<Dashboard />}
          />
          <Route
            path='billings'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardBillingList />
              </PrivateRoute>
            }
          />
          <Route
            path='billings/:bid/approve'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardBillingForm />
              </PrivateRoute>
            }
          />
          <Route
            path='billings/approval'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardBillingApproval />
              </PrivateRoute>
            }
          />
          <Route
            path='tickers'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardTickerForm />
              </PrivateRoute>
            }
          />
          <Route
            path='gen-tickers'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardGenTickerForm />
              </PrivateRoute>
            }
          />
          <Route
            path='stat'
            element={
              <PrivateRoute isAdmin={true}>
                <DashboardStat />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path='users' element={<UserPage />} >
          <Route
            index
            element={
              <PrivateRoute isAdmin={true}>
                <UserList />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path='bots' element={<BotPage />} >
          <Route
            index
            element={<TVList />}
          />
          <Route
            path='tv'
            element={<TVList />}
          />
          <Route
            path='tv/create'
            element={<TVForm />}
          />
          <Route
            path='tv/:bid/edit'
            element={<TVForm />}
          />
        </Route>
        <Route path='billings' element={<BillingPage />} >
          <Route
            index
            element={<BillingList />}
          />
          {/* <Route
            path='payment'
            element={<BillingForm />}
          /> */}
          <Route
            path=':bid/payment'
            element={<BillingForm />}
          />
        </Route>
        <Route path='settings' element={<SettingPage />} >
          <Route
            index
            element={<Setting />}
          />
          <Route
            path='keys'
            element={<KeyList />}
          />
          <Route
            path='keys/create'
            element={<KeyForm />}
          />
          <Route
            path='keys/:kid/edit'
            element={<KeyForm />}
          />
          <Route
            path='tokens'
            element={<TokenList />}
          />
          <Route
            path='tokens/create'
            element={<TokenForm />}
          />
          <Route
            path='tokens/:tid/edit'
            element={<TokenForm />}
          />
        </Route>
      </Route>
      <Route path='/403' element={<Forbidden />} />
      <Route path='/404' element={<NotFound />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default Index
